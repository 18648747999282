.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Gloria Hallelujah', cursive;
}

.center{
  display: flex;
  justify-content: center;
}

.pg-geral{
  display: flex;
  flex-direction: column;
}

.game-container{
  display: grid;
  grid-template-columns: 75px 75px 75px;
  gap: 20px;
  justify-content: center;
}

.box{
  width: 75px;
  height: 75px;
  font-weight: bold;
  font-size: 40px;
  box-shadow: 5px 5px 5px black;
  cursor: pointer;
  animation: mymove 15s infinite alternate;
}

.x{
  width: 75px;
  height: 75px;
  background-color: magenta;
  font-weight: bold;
  font-size: 40px;
  box-shadow: 5px 5px 5px black;
}

.o{
  width: 75px;
  height: 75px;
  background-color: aquamarine;
  font-weight: bold;
  font-size: 40px;
  box-shadow: 5px 5px 5px black;
}

@keyframes mymove{
  0% {
    background-color: red;
  }
  14%{
    background-color: orange;
  }
  28%{
    background-color: yellow;
  }
  42%{
    background-color: green;
  }
  65%{
    background-color: rgb(0, 0, 162);
  }
  82%{
    background-color: indigo;
  }
  100%{
    background-color: violet;
  }
}

.btn-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.btn{
cursor: pointer;
visibility: hidden;
}



.winner{
  display: hidden;
  color: black;
}

.input {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}
.input:hover {
    outline: none;
}
.wave {
    border-radius: 40px 0 40px 0;
    transition: 0.3s ease;
}
.waveA {
    background-color: lightcyan;
    border-radius: 40px 0 40px 0;
    color: blue;
    border: 2px solid rgb(91, 129, 255);
    box-shadow: 2px 2px 2px 1px rgba(60, 112, 255, 0.2);
    transition: 0.3s ease;
}
.waveA:hover {
    border: 3px solid rgb(0, 204, 255);
    box-shadow: 12px 12px 2px 1px rgba(60, 112, 255, 0.2);
}






















.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

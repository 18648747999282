.detalhes{
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-content: space-around;
}

.btn-opt{
	display: flex;
	flex-direction: column;
	padding: 10px;
}

